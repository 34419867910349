import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Spacers is good spacing systems are based on simple mathematical principles
with increments that are visually. This toolkit gives designers and developers
guidelines for effectively applying layout spacing, resulting in a more
consistent application of space across our product. You can chek out the guideline for more detail description.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { spacer } from "@legion-ui/core";
`}</code></pre>
    <div className="divi" />
    <h2>{`Preview`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Space`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Token Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pixels`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing1" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.25 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing2" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.5 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing3" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.75 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing4" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing5" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.25 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing6" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.5 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing7" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.75 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing8" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing9" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing9`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.25 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing10" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing10`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.5 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing11" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing11`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing12" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing12`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3.5 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`56px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing13" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing13`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing14" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing14`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing15" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing15`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing16" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing16`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`112px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing17" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing17`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing18" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing18`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`144px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing19" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing19`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`160px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing20" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing20`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`176px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing21" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing21`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`192px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing23" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing23`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`228px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing22" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing22`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`208px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing24" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing24`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`240px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing25" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing25`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing26" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing26`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`288px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing27" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing27`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`320px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="spacing28" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacing28`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24 rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`384px`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`There are two ways on how you can use the `}<inlineCode parentName="p">{`spacing`}</inlineCode>{` toolkit. First by using Legion V2’s components and second by using HTML elements.`}</p>
    <h3>{`Component`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`spacing`}</inlineCode>{` toolkit can be used on other properties too, for example the `}<inlineCode parentName="p">{`pt`}</inlineCode>{`, `}<inlineCode parentName="p">{`pb`}</inlineCode>{`, `}<inlineCode parentName="p">{`pr`}</inlineCode>{`, `}<inlineCode parentName="p">{`pl`}</inlineCode>{` etc. Please refer to each component’s documentation page for more information.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box mb="spacing1">...</Box>
<Box mb="spacing2">...</Box>
<Box mb="spacing3">...</Box>
<Box mb="spacing4">...</Box>
<Box mb="spacing5">...</Box>
<Box mb="spacing6">...</Box>
<Box mb="spacing7">...</Box>
<Box mb="spacing8">...</Box>
<Box mb="spacing9">...</Box>
<Box mb="spacing10">...</Box>
<Box mb="spacing11">...</Box>
<Box mb="spacing12">...</Box>
<Box mb="spacing13">...</Box>
<Box mb="spacing14">...</Box>
<Box mb="spacing15">...</Box>
<Box mb="spacing16">...</Box>
<Box mb="spacing17">...</Box>
<Box mb="spacing18">...</Box>
<Box mb="spacing19">...</Box>
<Box mb="spacing20">...</Box>
<Box mb="spacing21">...</Box>
<Box mb="spacing22">...</Box>
<Box mb="spacing23">...</Box>
<Box mb="spacing24">...</Box>
<Box mb="spacing25">...</Box>
<Box mb="spacing26">...</Box>
<Box mb="spacing27">...</Box>
<Box mb="spacing28">...</Box>
`}</code></pre>
    <h3>{`HTML Elements`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <div style={{ marginBottom: \`\${spacer.spacing1}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing2}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing3}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing4}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing5}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing6}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing7}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing8}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing9}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing10}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing11}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing12}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing13}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing14}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing15}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing16}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing17}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing18}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing19}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing20}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing21}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing22}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing23}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing24}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing25}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing26}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing27}\` }}>...</div>
  <div style={{ marginBottom: \`\${spacer.spacing28}\` }}>...</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      